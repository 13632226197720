import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationPublishJobPostArgs } from '../../generated/types';

// We only return the updated fields from the mutation

export const PUBLISH_JOB_POST = gql`
  mutation PublishJobPost($userUuid: String!, $jobUuid: String!, $isPublished: Boolean!) {
    publishJobPost(userUuid: $userUuid, jobUuid: $jobUuid, isPublished: $isPublished) {
      uuid
      status
      updatedAt
      updatedBy {
        uuid
      }
    }
  }
`;

export const usePublishJobPost = (
  options?: MutationHookOptions<Pick<Mutation, 'publishJobPost'>, MutationPublishJobPostArgs>
) =>
  useMutation<Pick<Mutation, 'publishJobPost'>, MutationPublishJobPostArgs>(PUBLISH_JOB_POST, {
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });
